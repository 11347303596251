<template>
  <v-container fluid>
    <v-tabs v-model="activeTab" class="my-5">
      <v-tab key="facturas"> HISTÓRICO DE FACTURAS</v-tab>

      <v-tab key="membresias"> Histórico de Membresías </v-tab>

      <v-tab key="eventos"> HISTÓRICO DE CURSOS Y EVENTOS </v-tab>

      <v-tab key="eventPoints" v-if="isImefOrganization(user.headOffice.id)"> PUNTOS DE CAPACITACIÓN </v-tab>
    </v-tabs>

    <v-tabs-items v-model="activeTab">
      <v-tab-item key="facturas">
        <div class="filter-container mt-5 mx-5">
          <span class="titlehist">Histórico de facturas</span>
          <!-- barra de navegación -->
          <div class="searchbar d-flex flex-row my-4">
            <v-btn
              fab
              small
              elevation="0"
              class="rounded-pill rounded-r-0"
              color="#feb300"
            >
              <!-- @click="" -->
              <v-icon size="20">mdi-magnify</v-icon>
            </v-btn>
            <input
              type="text"
              placeholder="Buscar facturas"
              class="rounded-pill rounded-l-0"
              v-model="invoiceSearch"
            />
          </div>
          <!-- información -->
          <v-simple-table height="100%" class="mx-10">
            <thead>
              <tr>
                <th
                  v-for="(title, i) in invoiceTableTitles"
                  :key="i"
                  class="text-left"
                >
                  {{ title }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(
                  { id, description, total, createdAt }
                ) in invoicesFiltered"
                :key="id"
              >
                <th>{{ id }}</th>
                <th>{{ description }}</th>
                <th>{{ total }}</th>
                <th>{{ createdAt }}</th>
                <th>
                  <div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          icon
                          @click="
                            getInvoiceFile({
                              invoice: id,
                              download: false,
                            })
                          "
                        >
                          <v-icon>mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>Visualizar factura</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          icon
                          @click="
                            getInvoiceFile({
                              invoice: id,
                              download: true,
                            })
                          "
                        >
                          <v-icon>mdi-download</v-icon>
                        </v-btn>
                      </template>
                      <span>Descargar Factura</span>
                    </v-tooltip>
                  </div>
                </th>
              </tr>
            </tbody>
            <v-dialog
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
              v-model="fileDialog"
            >
              <v-btn
                v-if="$vuetify.breakpoint.smAndDown != true"
                bottom
                right
                absolute
                fab
                color="accent"
                :style="{
                  position: 'absolute',
                  bottom: '50px',
                  right: '50px',
                }"
                @click="fileDialog = false"
                ><v-icon>mdi-close</v-icon></v-btn
              >
              <v-btn v-else @click="fileDialog = false" large bottom
                ><v-icon>mdi-close</v-icon></v-btn
              >
              <iframe
                type="application/pdf"
                :src="invoiceUrl"
                style="width: 100%; height: 100%; border: none"
              ></iframe>
            </v-dialog>
          </v-simple-table>
        </div>
      </v-tab-item>

      <v-tab-item key="membresias">
        <!-- contenido de la pestaña "Histórico de Membresías" -->
        <div class="filter-container mt-5 mx-5">
          <span class="titlehist">Histórico de Membresías</span>
          <!-- barra de navegación -->
          <div class="searchbar d-flex flex-row my-4">
            <v-btn
              fab
              small
              elevation="0"
              class="rounded-pill rounded-r-0"
              color="#feb300"
              @click="filterItems()"
            >
              <v-icon size="20">mdi-magnify</v-icon>
            </v-btn>
            <input
              type="text"
              placeholder="Buscar en membresías"
              class="rounded-pill rounded-l-0"
              v-model="searchField"
            />
          </div>
          <!-- información -->
          <v-simple-table height="100%" class="mx-10">
            <thead>
              <tr>
                <th
                  v-for="(title, i) in tableTitles"
                  :key="i"
                  class="text-left"
                >
                  {{ title }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(cont, i) in tableContentfilt"
                :key="i"
                class="text-left"
              >
                <!-- Columna de filtros -->
                <!-- <th class="px-0"></th> -->
                <th>
                  {{ cont.name }}
                </th>
                <th>
                  {{ cont.inicio }}
                </th>
                <th>
                  {{ cont.exp == "" ? "---" : cont.exp }}
                </th>
                <th class="text-uppercase">
                  {{ cont.type }}
                </th>
                <th>
                  {{ cont.costo }}
                </th>
                <!-- <th>
                  <span
                    class="text-decoration-underline"
                    :class="cont.estado"
                  >
                    {{ cont.estado }}
                  </span>
                </th> -->
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-tab-item>

      <v-tab-item key="eventos">
        <div class="filter-container mt-5 mx-5">
          <span class="titlehist pb-10">Histórico de Cursos y Eventos</span>
          <!-- Barra de búsqueda para eventos -->
          <div class="searchbar d-flex flex-row my-4">
            <v-btn
              fab
              small
              elevation="0"
              class="rounded-pill rounded-r-0"
              color="#feb300"
              @click="filterEvents()"
            >
              <v-icon size="20">mdi-magnify</v-icon>
            </v-btn>
            <input
              type="text"
              placeholder="Buscar en eventos"
              class="rounded-pill rounded-l-0"
              v-model="searchField2"
            />
          </div>
          <!-- Tabla para el histórico de eventos -->
          <v-simple-table height="300px" class="mx-10 pt-1">
            <thead>
              <tr>
                <th
                  v-for="(title, i) in eventTableTitles"
                  :key="i"
                  class="text-left"
                >
                  {{ title }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(event, index) in eventTableDataFiltered"
                :key="index"
                class="text-left"
              >
              <td v-for="(item) in Object.keys(event)" :key="item">
                {{event[item]}}
              </td>
                <!-- <td>{{ event.name }}</td>
                <td>{{ event.startDate.slice(0, 10) }}</td>
                <td>{{ event.endDate.slice(0, 10) }}</td>
                <td>{{ event.price }}</td>
                <td>{{ event.membersOnlyTruePrice || 0 }}</td>
                <td>{{ event.membersOnlyFalsePrice || 0 }}</td> -->
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-tab-item>

      <v-tab-item key="eventPoints" v-if="isImefOrganization(user.headOffice.id)">
        <div class="filter-container mt-5 mx-5">
        <span class="titlehist">Puntos de capacitación</span>
        <div class="d-flex align-center justify-space-between">
          <p class="ml-4 mt-4" style="width: 50%">
            En este apartado podrás visualizar y descargar los puntos de capacitación para los eventos a los que has asistido.
          </p>
          <v-btn class="rounded-pill text-capitalize" style="font-weight: bold" elevation="0" :color="color.secondary" @click.prevent="postMemberPointsCertificate()">Descargar Certificado</v-btn>
        </div>
        <v-simple-table class="px-8">
          <thead>
              <tr>
                <th
                  v-for="({ value, align = 'left' }, i) in userPointsHeaders"
                  :key="i"
                  :class="`text-${align}`"
                >
                  {{ value }}
                </th>
              </tr>
            </thead>
                <tbody>
                    <tr v-for="(item, index) in userPointList" :key="index">
                        <td class="text-uppercase" style="font-weight: 700;">{{ item.event.name }}</td>
                        <td class="text-center">{{ formatDate(item.createdAt) }}</td>
                        <td class="text-end text-bold">{{ item.points }}</td>
                    </tr>
                </tbody>
            </v-simple-table>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { colors } from '@/constants/colors.js';
import { formatPrice, downloadFile } from "@/utils";
import { formatDate } from "@/utils/dates.js";
import { mapActions, mapState, mapMutations } from "vuex";
import { isImefOrganization } from "../../share/imef";

export default {
  data() {
    return {
      searchField: "",
      searchField2: "",
      tableTitles: ["Nombre", "Inicio", "Expiración", "Concepto", "Costo"],
      invoiceTableTitles: [
        "Id",
        "Concepto",
        "Monto",
        "Fecha de emisión",
        "Acciones",
      ],
      tableContent: [],
      invoiceSearch: "",
      invoices: [],
      color: colors,
      invoicesFiltered: [],
      userPointList: [],
      userPointsHeaders: [
        { value: "Evento" },
        { value: "Fecha", align: "center"},
        { value: "Puntos", align: "right" },
      ],
      invoiceUrl: "",
      fileDialog: false,
      organizationType: {
        FIRST_PAYMENT: "Pago inicial",
        HEAD_OFFICE: "Renovación",
        BRANCH: "Membresía Local",
        INSCRIPTION: "Inscripción",
      },
      statusType: {
        ACTIVE: "Activo",
        EXPIRED: "Expirado",
        INACTIVE: "Inactivo",
      },
      tableContentfilt: [],

      activeTab: "membresias",
      eventTableTitles: [],
      eventTableData: [],
      isEventDataLoaded: false,
      eventSearchField: "",
      eventTableDataFiltered: [],
    };
  },
  watch: {
    invoiceSearch(value) {
      if (value != "") {
        this.filterInvoices(value, (filter) => {
          this.invoicesFiltered = filter;
        });
      } else {
        this.invoicesFiltered = this.invoices;
      }
    },
  },
  methods: {
    ...mapActions("member", ["fetchMemberItems"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapMutations("notification", ["show"]),
    isImefOrganization,
    filterInvoices(value, callback) {
      setTimeout(() => {
        let filt = [...this.invoices].filter((element, index) => {
          return (
            element.description.toLowerCase().includes(value.toLowerCase()) ||
            element.id.toLowerCase().includes(value.toLowerCase())
          );
        });
        callback(filt);
      }, 1500);
    },

    filterItems() {
      let ft = this.searchField;
      this.searchField = "";
      this.tableContentfilt = this.tableContent.filter((cont) => {
        return (
          cont.estado.toLowerCase().includes(ft.toLowerCase()) ||
          cont.costo.toLowerCase().includes(ft.toLowerCase()) ||
          cont.memLev.toLowerCase().includes(ft.toLowerCase())
        );
      });
    },

    formatDate(createdDate) {
            const date = new Date(createdDate);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        },

    filterEvents() {
      const searchText = this.searchField2.toLowerCase();
      this.searchField2 = "";
      this.eventTableDataFiltered = this.eventTableData.filter((event) => {
        return (
          event.name.toLowerCase().includes(searchText) ||
          event.startDate.slice(0, 10).includes(searchText) ||
          event.endDate.slice(0, 10).includes(searchText) ||
          event.costo.includes(searchText)
        );
      });
    },

    formatInvoices(invoices = []) {
      return invoices.map(
        ({
          invoice,
          description,
          price,
          date,
        }) => {
          return {
            id: invoice,
            description,
            total: formatPrice(price),
            createdAt: formatDate(date),
          };
        }
      );
    },

    async fetchUserInvoices() {
      try {
        this.loading();
        const response = await this.axios({
          method: "GET",
          url: "/invoices/member-invoice-paginate",
          params: {
            userId: this.user.id,
          },
        });
        if (response.status == 200) {
          this.invoices = this.formatInvoices(response.data.invoices);
          this.invoicesFiltered = this.invoices;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loaded();
      }
    },

    async getInvoiceFile({ invoice, download = false }) {
      try {
        this.loading();
        const { status, data } = await this.axios({
          method: "POST",
          url: "/invoices/download-member-invoice",
          data: {
            invoiceId: invoice,
            organizationId: this.user.headOffice.id,
            download,
          },
          responseType: "arraybuffer",
        });
        if (status === 200) {
          if (download) {
            downloadFile(data, "application/zip", "invoice.zip");
          } else {
            let blob = new File([data], "invoice.pdf", {
              type: `application/pdf`,
            });
            const fileUrl = URL.createObjectURL(blob);
            this.invoiceUrl = fileUrl;
            this.fileDialog = true;
            console.log(fileUrl);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loaded();
      }
    },

    async fetchMemberEvents() {
      try {
        this.loading();
        const response = await axios.get("/members/event-orders");

        if (!response.data) {
          this.eventTableData = [];
        } else {
          console.log(response.data);
          this.eventTableData = response.data.content;
          this.eventTableTitles = response.data.headers;
          // response.data.map((event) => {
          //   const membersOnlyTruePrice =
          //     event.membersOnlyTruePrice !== undefined &&
          //     event.membersOnlyTruePrice !== 0
          //       ? "$" + event.membersOnlyTruePrice + " MXN"
          //       : "$" + event.price + " MXN";

          //   const membersOnlyFalsePrice =
          //     event.membersOnlyFalsePrice !== undefined &&
          //     event.membersOnlyFalsePrice !== 0
          //       ? "$" + event.membersOnlyFalsePrice + " MXN"
          //       : "$" + event.price + " MXN";

          //   return {
          //     price: "$" + event.price + " MXN",
          //     name: event.name,
          //     startDate: event.startDate,
          //     endDate: event.endDate,
          //     startTime: event.startTime,
          //     endTime: event.endTime,
          //     membersOnlyTruePrice,
          //     membersOnlyFalsePrice,
          //   };
          // });
        }

        this.isEventDataLoaded = true;

        this.eventTableDataFiltered = this.eventTableData;

        this.loaded();
      } catch (error) {
        console.error(error);
        this.loaded();
      }
    },

    async fetchUserPoints() {
            try {
                let response = await this.axios({
                    method: 'GET',
                    url: `/events/points/${this.user._id}`,
                });
                this.userPointList = response.data?.pointsArray || [];
            } catch (error) {
                console.log(error);
            }
        },

    async postMemberPointsCertificate() {
      this.loading();
      try {
        let response = await this.axios({
          method: 'POST',
          url: '/events/points/get-certificate',
          data: {
            memberId: this.user._id
          }
        });

        if (response.status == 200) {
          if (response.data.constancyUrl) {
            window.location.href = response.data.constancyUrl;
          }
        }
        console.log(response)
      } catch (error) {
        this.show({
          text: "Ha habido un error con la descarga de su certificado, por favor intente nuevamente",
          color: "error",
        })
      } finally {
        this.loaded();
      }
    }
  },

  computed: {
    ...mapState("member", ["items"]),
    ...mapState("user", ["user"]),
  },

  async mounted() {
    // Llamada a la función fetchMemberEvents después de montar el componente

    // Resto de tu código en el método mounted
    this.loading();
    let branch = localStorage.getItem("affiliationProfile");
    let its = {};
    let objts = {};

    if (!this.items.data) {
      its = (await this.fetchMemberItems(branch)).data.items;
    } else {
      its = this.items.data.items;
    }
    try {
      await this.fetchUserPoints();
      await this.fetchUserInvoices();
      await this.fetchMemberEvents();

      objts = its.map((ob) => {
        console.log(ob.status);
        let item = {
          name: ob.affiliation
            ? ob.affiliation.name
            : ob.affiliationInscription
            ? ob.affiliationInscription.description
            : "",
          inicio: ob.createdAt.split("T").shift(),
          exp: "2023-05-23",
          type: this.organizationType[ob.type],
          costo: ob.total
            ? `$${ob.total} MXN`
            : ob.order
            ? `$${ob.order.total} MXN`
            : "$0 MXN",
          estado: this.statusType[ob.status],
        };

        const { affiliateInformation } = ob;

        if (affiliateInformation) {
          if (
            affiliateInformation.inactiveDate &&
            affiliateInformation.expirationDate
          ) {
            item.exp = moment(affiliateInformation.inactiveDate).format(
              "YYYY-MM-DD"
            );
          } else if (affiliateInformation.inactiveDate) {
            item.exp = moment(affiliateInformation.inactiveDate).format(
              "YYYY-MM-DD"
            );
          } else if (affiliateInformation.expirationDate) {
            item.exp = moment(affiliateInformation.inactiveDate).format(
              "YYYY-MM-DD"
            );
          }
        }
        return item;
      });
      objts.forEach((obj) => {
        this.tableContent.push(obj);
      });
      this.tableContentfilt = this.tableContent;
    } catch (error) {
      console.log(error);
    } finally {
      this.loaded();
    }
  },
};
</script>

<style scoped>
@font-face {
  font-family: "Montserrat-Black";
  src: url("/src/assets/fonts/Montserrat/MONTSERRAT_Montserrat-Black.otf")
    format("otf");
}

.titlehist {
  font-family: "Montserrat-Black", Fallback, sans-serif;
  font-weight: 800;
  font-size: 20px;
}

input::placeholder {
  font-size: 15px;
  color: #d4dcd4;
}

input {
  padding-left: 20px;
  width: 30rem;
  max-width: 30rem;
  min-width: 10rem;
  border: 0;
  border-color: transparent;
  background-color: #f9fafc;
}

input:focus {
  outline: none;
  border: 1px solid transparent;
}

th {
  font-weight: 400;
  font-size: 5px;
}

.Activo {
  color: #7cd6b7;
  font-weight: 600;
}

/* .Expirado {
  color: #b1b1b1;
  font-weight: 600;
}

.Inactivo {
  color: red;
  font-weight: 600
} */
</style>
